import React from 'react'
import { Link } from 'react-router-dom'
import CategorySlider from '../CategorySlider/CategorySlider'

export const Header = ({ isHeaderFooterShown }) => {
    return (
        <>
            {isHeaderFooterShown && <div className="sticky top-0 z-50 bg-[#FFF] py-4 px-0 lg:px-4 xl:px-0 overflow-hidden">
                <nav className="container mx-auto">
                    <div className="flex justify-center ">
                        <div className="col-span-12 lg:col-span-3 flex items-center lg:items-start mb-1.5 md:mb-3.5 lg:mb-0">
                            <div className="mx-auto lg:mx-0 mb-1.5 md:mb-0 justify-center">
                                <Link to='/'>
                                    <img src='../../assets/images/logo.png' width="250px" />
                                </Link>
                            </div>
                        </div>
                        {/* <div className="col-span-12 lg:col-span-9 ">
                            <div className='mx-3 lg:mx-0 xl:mx-0'>
                                <div className="overflow-x-scroll no_scrollbar">
                                    <CategorySlider />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </nav>
            </div>}
        </>

    )
}

export default Header
