import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Outlet, useLocation } from 'react-router-dom'
import { useEffect } from 'react'

export const Layout = ({ configData, isHeaderFooterShown, setIsHeaderFooterShown }) => {
    const windowLocationInfo = useLocation();
    useEffect(() => {
        const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
        if (windowLocationInfo?.pathname.includes('/g/') && isIos) {
            setIsHeaderFooterShown(false);
        } else {
            setIsHeaderFooterShown(true);
        }
    }, [windowLocationInfo]);

    return (
        <>
            <div className="">
                <Header isHeaderFooterShown={isHeaderFooterShown} />
                <Outlet />
                <Footer configData={configData} isHeaderFooterShown={isHeaderFooterShown} />
            </div>
        </>
    )
}

export default Layout
