import React, { useEffect, useState } from 'react'
import { FaPlay } from 'react-icons/fa';

const AdCountDown = ({ onClose, isOpen }) => {
    const [countDown, setCountDown] = useState(5);

    useEffect(() => {
        let timer;

        if (countDown > 0) {
            timer = setInterval(() => {
                setCountDown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }

        if (countDown === 0) {
            //   onClose();
        }

        return () => clearInterval(timer);
    }, [isOpen, onClose, countDown]);

    useEffect(() => {
        if (isOpen) {
            setCountDown(5); // Reset the countdown when the modal opens
        }
    }, [isOpen]);
    return (
        <div>
            <div className='text-center mt-5 lg:mt-10'>
                {countDown > 0 && <span className='font-semibold text-lg text-white py-3'>Continue in {countDown}s</span>}

                {countDown === 0 &&
                    <div className='w-fit bg-[#30B109] rounded-full flex cursor-pointer mx-auto justify-center gap-2 py-3 px-6' onClick={onClose}>
                        <FaPlay className='mt-1 text-white' /> <b className='text-[#ffff] text-xl'>  Continue</b>
                    </div>
                }
            </div>
        </div>
    )
}

export default AdCountDown